import React, { Component } from "react";
import Proofivy from "../contracts/proofivy.json";
import getWeb3 from "./getWeb3";
import Button from '@material-ui/core/Button';
import configuration from './configuration';

const button_style_pop_up = {
  button: {
    borderRadius: 0,
    backgroundColor: "black",
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 298,
  },
  buttonDisabled: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 298,
  },
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  state = { 
  	web3: null,
  	accounts: null,
  	contract: null,
  	buySuccess: false,
  	loading_message: "Connecting to your Ethereum wallet...",
  	status_text: '',
  	button_active: true
   };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();
      console.log(networkId)
      const deployedNetwork = configuration.network_id
      const deployedAddress = configuration.proofivy_address
      if (networkId !== deployedNetwork){
      	this.props.handleWeb3Error('Wrong network, connect to ' + configuration.network_name)
      	this.setState({ status_text: 'Wrong network, connect to ' + configuration.network_name, button_active: false })
      } else {
      const instance = new web3.eth.Contract(
        Proofivy.abi,
        deployedNetwork && deployedAddress,
      );
      console.log(accounts)
      console.log(instance)


      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contract: instance, status_text: 'Wallet ' + accounts[0].toString().substring(0, 9) + '...' });}
    } catch (error) {
      // Catch any errors for any of the above operations.
 	  // this.props.handleWeb3Error('You need a web3 wallet')
 	  this.setState({loading_message: "Use an Ethereum wallet like Metamask to submit your message"})
      console.error(error);
    }
  };

  handleConfirmClick = async () => {
  	this.setState({ button_active: false })
    const { accounts, contract } = this.state
    this.setState({ status_text: 'Confirm your transaction...' });

    contract.methods.add_guild_admin(this.props.guild, this.props.memberAddress).send({ from: accounts[0], value: 0,
                                                                                  maxPriorityFeePerGas: null, maxFeePerGas: null })
					.on('transactionHash', function(hash){ this.setState({ status_text: 'Adding admin...⏳' }) }.bind(this))
					.once('confirmation', function(confNumber, receipt){
						console.log(receipt)
						console.log(confNumber)
						this.setState({ status_text: 'Succes!! 🥂' })
					}.bind(this))
					.once('error', function(error, receipt){
						console.log(receipt)
						console.log(error)
						this.setState({ status_text: 'Something went wrong 😢' })
					}.bind(this))

  }
  
  /*
  componentWillUnmount(){
  	clear(handleConfirmClick)
  }
  */


  // return <div>Connecting to your Ethereum Wallet...</div>
  render() {
    if (!this.state.web3) {
      return <div>{this.state.loading_message}</div>
    }
    return (
      <div className="BuyApp">
      	<p>admin: {this.props.memberAddress.toString().substring(0, 9) + '...'}</p>
	    <div style={{lineHeight: 2}}>
	     <Button
	       disabled={!this.state.button_active}
	       style={{
	        ...button_style_pop_up.button,
	        ...((!this.state.button_active) ? button_style_pop_up.buttonDisabled : {}),
	       }}
	       onClick={() => this.handleConfirmClick()}> {'Add admin'}
	     </Button>
	     </div>
	     <p> </p>
	     <div style={{color: 'grey'}}>{this.state.status_text}</div>
      </div>
    );
  }
}

export default App;
